import { default as aboutOPmbUpAMrXMeta } from "/var/www/dreamly.cloud/pages/about.vue?macro=true";
import { default as calendarFQkXWXmc1MMeta } from "/var/www/dreamly.cloud/pages/calendar.vue?macro=true";
import { default as emaily1L6BtmpmJMeta } from "/var/www/dreamly.cloud/pages/change/email.vue?macro=true";
import { default as passworddGdDav0HiFMeta } from "/var/www/dreamly.cloud/pages/change/password.vue?macro=true";
import { default as usernamebSS9fl7o3QMeta } from "/var/www/dreamly.cloud/pages/change/username.vue?macro=true";
import { default as _91date_93Mok4x9b5Q5Meta } from "/var/www/dreamly.cloud/pages/day/[date].vue?macro=true";
import { default as donateVQWlovbWAIMeta } from "/var/www/dreamly.cloud/pages/donate.vue?macro=true";
import { default as _91_91id_93_93tdkf1U7Ji2Meta } from "/var/www/dreamly.cloud/pages/dream/[[id]].vue?macro=true";
import { default as guideBHWP2J4pgiMeta } from "/var/www/dreamly.cloud/pages/guide.vue?macro=true";
import { default as homeal4xuJkBaNMeta } from "/var/www/dreamly.cloud/pages/home.vue?macro=true";
import { default as imprint201UbbDLIWMeta } from "/var/www/dreamly.cloud/pages/imprint.vue?macro=true";
import { default as indexipfmjogwuZMeta } from "/var/www/dreamly.cloud/pages/index.vue?macro=true";
import { default as loginDwOYFJkZ7aMeta } from "/var/www/dreamly.cloud/pages/login.vue?macro=true";
import { default as privacyHA5tAMc56GMeta } from "/var/www/dreamly.cloud/pages/privacy.vue?macro=true";
import { default as profileDWNIhfh4PpMeta } from "/var/www/dreamly.cloud/pages/profile.vue?macro=true";
import { default as registeruSZzowYJSTMeta } from "/var/www/dreamly.cloud/pages/register.vue?macro=true";
import { default as search0UB2pTwboSMeta } from "/var/www/dreamly.cloud/pages/search.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutOPmbUpAMrXMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar",
    meta: calendarFQkXWXmc1MMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: "change-email",
    path: "/change/email",
    meta: emaily1L6BtmpmJMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/change/email.vue").then(m => m.default || m)
  },
  {
    name: "change-password",
    path: "/change/password",
    meta: passworddGdDav0HiFMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/change/password.vue").then(m => m.default || m)
  },
  {
    name: "change-username",
    path: "/change/username",
    meta: usernamebSS9fl7o3QMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/change/username.vue").then(m => m.default || m)
  },
  {
    name: "day-date",
    path: "/day/:date()",
    meta: _91date_93Mok4x9b5Q5Meta || {},
    component: () => import("/var/www/dreamly.cloud/pages/day/[date].vue").then(m => m.default || m)
  },
  {
    name: "donate",
    path: "/donate",
    meta: donateVQWlovbWAIMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/donate.vue").then(m => m.default || m)
  },
  {
    name: "dream-id",
    path: "/dream/:id?",
    meta: _91_91id_93_93tdkf1U7Ji2Meta || {},
    component: () => import("/var/www/dreamly.cloud/pages/dream/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "guide",
    path: "/guide",
    meta: guideBHWP2J4pgiMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/guide.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homeal4xuJkBaNMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "imprint",
    path: "/imprint",
    meta: imprint201UbbDLIWMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexipfmjogwuZMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginDwOYFJkZ7aMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyHA5tAMc56GMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileDWNIhfh4PpMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registeruSZzowYJSTMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    meta: search0UB2pTwboSMeta || {},
    component: () => import("/var/www/dreamly.cloud/pages/search.vue").then(m => m.default || m)
  }
]